import React from 'react';

type ArrowToTopIconProps = {
  className?: string;
  fill?: string;
  height: number;
}

const ArrowToTopIcon = ({
  className,
  fill,
  height,
}: ArrowToTopIconProps): JSX.Element => (
  <svg
    className={className}
    fill={fill}
    height={height}
    viewBox="0 0 384 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 32h336c13.3 0 24 10.7 24 24v24c0 13.3-10.7 24-24 24H24C10.7 104 0 93.3 0 80V56c0-13.3 10.7-24 24-24zm232 424V320h87.7c17.8 0 26.7-21.5 14.1-34.1L205.7 133.7c-7.5-7.5-19.8-7.5-27.3 0L26.1 285.9C13.5 298.5 22.5 320 40.3 320H128v136c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24z"
    />
  </svg>
);

export default React.memo(ArrowToTopIcon);
