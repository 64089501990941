import cx from 'classnames';
import React from 'react';

type SectionProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const Section = ({
  children,
  className,
  id,
}: SectionProps): JSX.Element => (
  <div
    className={cx([
      'u-padding-bottom-xl',
      'u-padding-top-xl',
      className && className,
    ])}
    id={id}
  >
    { children }
  </div>
);

export default React.memo(Section);
